<template lang="pug">
    footer.main_footer
        .grid
            .about
                .about--logo.margin-bottom--lg
                    router-link(to="/" alt="Inicio").logo
                        svg.icon
                            use(xlink:href="#icon-imagotipo")
                        span.off_screen Grúas Hércules
                .about--description
                    div
                        p Grúas Hércules, es una empresa michoacana dedicada a la renta de grúas telescópicas y articuladas, que busca brindar solución a nuestros clientes para los problemas de izaje y carga, de una manera profesional, personal, eficaz y eficiente.
            .navigation
                .grid.margin-bottom--lg
                    div
                        strong.margin-bottom--sm.text--ls-sm.text--uppercase Menú
                        ul
                            li
                                router-link(to="/" alt="inicio")
                                    span Inicio
                            li
                                router-link(:to="{name: 'machinery', params: { type: 'todas' }}" alt="Maquinaria")
                                    span Maquinaria
                            li
                                router-link(to="/contacto" alt="Contacto")
                                    span Contacto
                    div
                        strong.margin-bottom--sm.text--ls-sm.text--uppercase Maquinaria
                        ul
                            li
                                router-link(:to="{name: 'machinery', params: { type: 'gruas' }}" alt="Grúas")
                                    span Grúas
                            li
                                router-link(:to="{name: 'machinery', params: { type: 'titanes' }}" alt="Titanes")
                                    span Titanes
                            li
                                router-link(:to="{name: 'machinery', params: { type: 'excavadoras' }}" alt="Excavadoras")
                                    span Excavadoras
                .grid.margin-bottom--lg
                    div
                        strong.margin-bottom--sm.text--ls-sm.text--uppercase Contactanos
                        ul
                            li
                                a(href="tel:+524433231835" alt="Teléfono")
                                    span (443) 3 23 18 35
                            li
                                a(href="tel:+524433234152" alt="Teléfono")
                                    span (443) 3 23 41 52
                            li
                                a(href="mailto:maniobras@gruashercules.mx" alt="Correo electrónico")
                                    span maniobras@gruashercules.mx
                .grid
                    div
                        strong.margin-bottom--sm.text--ls-sm.text--uppercase Siguenos
                        ul.social.flex_content--horizontal
                            li
                                a(href="https://www.facebook.com/RentaGruasHercules" alt="Facebook" target="_blank" rel="noopener")
                                    svg.icon
                                        use(xlink:href="#icon-facebook")
                                    span.off_screen Facebook
                            li
                                a(href="https://www.youtube.com/channel/UCtc9-_LpPZbV_HaUZ9bvsjw" alt="YouTube" target="_blank" rel="noopener")
                                    svg.icon
                                        use(xlink:href="#icon-youtube")
                                    span.off_screen YouTube
        .sub-footer.flex_content--horizontal.jc_space-between
            ul.flex_content--horizontal.ai_center
                li.copyright
                    span.text--sm.text--ls-sm &copy2019 Grúas Hércules
            ul.flex_content--horizontal.ai_center
                li
                    span.text--sm.text--ls-sm Creado por 
                    a(href="https://neodiko.com/" alt="Neodiko" target="_blank" rel="noopener")
                        span.text--sm.text--ls-sm NEODIKO
</template>

<script>
export default {
    name: 'include_footer'
}
</script>